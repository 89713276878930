.bg-img-top{
    background-image: linear-gradient(45deg,rgba(0, 134, 146, 0.952),rgba(9, 16, 40, 0.945),rgba(0, 18, 71, 0.945));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bg-img-top1{
    background-image: linear-gradient(135deg,rgba(0, 134, 146, 0.952),rgba(9, 16, 40, 0.945),rgba(0, 18, 71, 0.945));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.text-img-top{

    background-image: linear-gradient(45deg,rgba(94, 100, 250, 0.952),rgba(253, 51, 51, 0.945),rgba(252, 242, 61, 0.945));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: transparent;
    -webkit-background-clip:text;
}
.link-hover{
    color: rgba(255, 255, 255, 0.993);
}
.link-hover:hover{
    color:rgb(17, 218, 218) ;
}
