.sizes{
    position: absolute;
    top: 16rem;
    left: -1rem;
}
.bg-about{
    background-image: linear-gradient(45deg,rgba(0, 134, 146, 0.952),rgba(9, 16, 40, 0.945),rgba(0, 18, 71, 0.945));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.my-element {
    display: inline-block;
    margin: 0 0.5rem;
  
    animation: bounce; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; /* don't forget to set a duration! */
  }
@media only screen and (max-width:688px) {
    .sizes{
        display: none;
    }
}