.bg-img{
    background-image: linear-gradient(80deg,rgba(2, 0, 46, 0.952),rgba(106, 106, 255, 0.5) );
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.link-hover{
    color: rgba(255, 255, 255, 0.993);
}
.link-hover:hover{
    color:rgb(17, 218, 218) ;
}